.shiny-metal {
  position: absolute;
  top: -100%;
  left: -100%;
  width: 200%;
  height: 200%;
  filter: blur(10px);
  opacity: 0.4;
  transform: rotate(45deg);
}
