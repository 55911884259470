/* ChatComponent.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.chatCcontainer {
  @apply flex flex-col w-full h-full flex-1 overflow-hidden mb-4;
}

.chatBox {
  @apply w-full h-full max-h-full p-6 mb-4 overflow-y-auto;
}

.messageContainer {
  @apply w-full flex flex-row;
}

.messageFromMe {
  @apply p-2 mb-2 text-right bg-gray-200 rounded-md shrink;
}

.messageFromGpt {
  @apply p-2 mb-2 text-left bg-blue-200 rounded-md shrink;
}

.inputForm {
  @apply flex w-full flex-grow-0;
}

.inputField {
  @apply flex-1 px-2 py-2 bg-white border border-gray-300 rounded-l-md focus:outline-none focus:border-teal-500;
}

.sendButton {
  @apply px-4 py-2 bg-teal-500 text-white font-semibold rounded-r-md hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-opacity-60;
}
